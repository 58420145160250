import React, { useEffect, useState } from "react"
import { IProps } from './types'
import * as utils from '../../lib/messageUtils'
import { MessagingConstants } from "../../lib/enums";

const TermsOfServiceNotification = (props: IProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const { company, sender } = props

  /*
    MESSAGING - TJSKR-1527: Sends a message to parent component when user accepts the terms of service from one opened chat;
    When user dismisses the terms modal from a chat, the modal from the other opened chats should be dismissed as well.
  */
  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event?.data?.type === MessagingConstants.TERMS_ACCEPTED_FROM_OTHER_CHAT) {
        handleDismissModal()
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleDismissModal = () => {
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
    utils.updateTermsOfServiceAgreed(sender.userArn)
    window.parent.postMessage({ type: MessagingConstants.TERMS_OF_SERVICE_ACCEPTED, userArn: sender.userArn }, '*');
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 10px",
        margin: "0px 0px 8px 0px",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        color: "#fff",
        border: "1px solid #ddd",
        backgroundColor: "transparent",
        // width: "100%",
        position: "sticky",
        top: "0",
        zIndex: "1000",
        // margin: "0px 10px",
      }}
    >
      <div style={{ flex: 1, marginRight: "5px" }}>
        <p style={{ margin: 0, fontSize: "12px", color: "#606060" }}>
            This chat may be recorded and used by { company } and its third-party vendors.  By chatting, you agree to our <a href="https://www.jobtarget.com/terms-of-use" target="_blank">Terms</a>  and understand your information will be processed per our <a href="https://www.jobtarget.com/privacy-policy" target="_blank">Privacy Policy</a>.
        </p>
      </div>
      <button
        onClick={handleClose}
        style={{
          background: "none",
          border: "none",
          color: "blue",
          fontSize: "16px",
          cursor: "pointer",
          position: "absolute",
          top: "8px",
          right: "8px",
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default TermsOfServiceNotification;
