const getDisplayName = (user: any, termsOfServiceAgreed: boolean) => {
    const { first_name, last_name, email, role } = user
    let username = `${first_name} ${last_name}`
    // if(role =="Recruiter" || (role =="Jobseeker" && termsOfServiceAgreed)) {
    if(username && username.trim() !== '') return username
    else return email.split('@')[0]
    // }
    return ''
  }

export default getDisplayName