import theme from '../../theme'
import { IProps, MessageResponse } from './types'
import Navbar from '../Navbar'
import ChatList from '../ChatList'
import ChatInput from '../ChatInput'
import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from '../../style/styles'
import { Grid, IconButton, ThemeProvider, Typography } from '@mui/material'
import * as utils from '../../lib/messageUtils'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelData, ChannelInfo, RootState } from '../../redux/channel/types'
import { setChannelData,updateChannelMessageList,updateTermsAgreed } from '../../redux/channel/actions'
import messagingService from '../../services/MessagingService'
import { MessagingConstants } from '../../lib/enums'
import TermsOfService from '../TermsOfService'
import getDisplayName from '../../lib/getDisplayName'
import Timezone from '../Timezone'
import TermsOfServiceNotification from '../TermsOfServiceNotification'

const Chat = (props: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { sender, receiver, channel, job, connections, token, onMinimizeChat } = props
  const [isEndedConversation, setIsEndedConversation] = useState(false)
  const channelsReducer = useSelector((state: RootState) => state.channels.channels)
  const loggedInUserArn = sender.userArn
  const { channelName } = channel
  const [shouldLoadMessages, setShouldLoadMessages] = useState(false)
  const [channelArn, setChannelArn] = useState<string | null>(null)
  const [channelData, setChannelReducer] = useState<ChannelData>()
  const [termsOfServiceAgreed, setTermsOfServiceAgreed] = useState(true)
  const [inputFocused, setInputFocused] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(sender?.timezone);
  const [endOrReenableActionDoer, setEndOrReenableActionDoer] = useState<string | null>(null)
  const [adminID, setAdminId] = useState<string>("")
  const [updatedChannel, setUpdatedChannel] = useState<any>(channel)
  const [updatedConnections, setUpdatedConnections] = useState<any>(connections)
  const [showChat, setShowChat] = useState<boolean>(true)
  const componentRef = useRef<HTMLDivElement>(null);

  /* TERMS OF SERVICE NOTIFICATION: to track height of component */
  useEffect(() => {
    if (componentRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          const height = entry.contentRect.height;
          document.documentElement.style.setProperty('--default-terms-height', `${height}px`);
          console.log('Terms height set to:', height);
        }
      });
      resizeObserver.observe(componentRef.current);
      return () => resizeObserver.disconnect();
    }
  });

  const handleTimezoneChange = (timezone: string ) => {
    setSelectedTimezone(timezone)
  }

  useEffect(() => {
    if(channel?.metadata){
      setAdminId(JSON.parse(channel.metadata).Admin)
    }
  }, [channel?.metadata])
 

  useEffect(() => {
    setTermsOfServiceAgreed(sender.termsAgreed);
  }, [sender.termsAgreed])

  // useEffect(() => {
  //   const showChat = sender.role == "Jobseeker" || (sender.role == "Recruiter" && termsOfServiceAgreed )
  //   setShowChat(showChat)
  // }, [sender])

  const handleTermsAction = (agreedToTerms: boolean) => {
    setTermsOfServiceAgreed(agreedToTerms)
    if (agreedToTerms) {
      utils.updateTermsOfServiceAgreed(sender.userArn)
      if(channelsReducer[channelName]?.userInfo) {
        dispatch(updateTermsAgreed(channelName, agreedToTerms))
      }
    }
  }

  const [isTimezoneDialogOpen, setTimezoneDialogOpen] = useState(false);
  const handleOpenTimezoneDialog = () => {
    setTimezoneDialogOpen(true);
  };

  const handleCloseTimezoneDialog = () => {
    setTimezoneDialogOpen(false);
  };

  useEffect(() => {
    if(!channelsReducer[channelName] || channelsReducer[channelName]?.channelArn) {
      fetchChannel().then((returnedChannel: any) => {
        setChannelInfo(returnedChannel)
      }).catch(err => { throw err })
    }
  }, [channelName, channelArn, sender])

  useEffect(() => {
    setChannelReducer(channelsReducer[channelName])
    if (shouldLoadMessages) {
      handleLoadMessages()
      setShouldLoadMessages(false)
    }
  }, [channelsReducer, shouldLoadMessages])

  useEffect(() => {
    messagingService.subscribeToMessageUpdate(callbackProcessor)
    return () => {
      messagingService.unsubscribeFromMessageUpdate(callbackProcessor)
    }
  }, [])

  const fetchChannel = async () => {
    const channelIdentifiers = {
      senderId: sender.external_user_id,
      receiverId: receiver.external_user_id,
      jobId: job.job_id
    }
    const members = [`${sender.external_user_id}-${sender.role}`, `${receiver.external_user_id}-${receiver.role}`]
    const channel = await utils.retrieveChannel(sender.userArn, null, members, channelIdentifiers)
    if(channel) {
      const channelMetadata = JSON.parse(channel.ChannelSummary?.Metadata)
      const adminUserArn = sender.role.toUpperCase() === MessagingConstants.RECRUITER ? sender.userArn : receiver.userArn
      const endConversationTimestamp = channelMetadata?.EndConversationTimestamp || null
      const isEndedConversation = endConversationTimestamp ? true : false
      const actionDoer = channelMetadata?.EndOrReenableActionDoer
      setUpdatedChannel(channel)
      setChannelArn(channel?.ChannelSummary?.ChannelArn)
      setIsEndedConversation(isEndedConversation)
      setEndOrReenableActionDoer(actionDoer)

      const blockedBy = channelMetadata?.BlockedBy
      if (blockedBy && blockedBy.trim() !== '') {
        if (blockedBy == loggedInUserArn) {
          setUpdatedConnections({ isBlocked: false, isBlockedLink: true})
        } else {
          setUpdatedConnections({ isBlocked: true, isBlockedLink: false})
        }
      } else {
        setUpdatedConnections({ isBlocked: connections.isBlocked, isBlockedLink: connections.isBlockedLink})
      }

      return channel
    } return null
  }

  const callbackProcessor = async(message: MessageResponse) => {
    const messageType = message?.headers['x-amz-chime-event-type']
    const record = JSON.parse(message?.payload)
    const isRecordNotEmpty = record && record?.Content
    switch(messageType) {
      case 'CREATE_CHANNEL_MESSAGE':
        if(isRecordNotEmpty) {
          if(utils.checkIfBlockIndicatorMessage(record.Content)) {
            fetchChannel()
          }
          handleLoadMessages()
          setShouldLoadMessages(true)
        }
        break
      case 'CREATE_CHANNEL_MEMBERSHIP':
      case 'UPDATE_CHANNEL':
        fetchChannel()
        handleLoadMessages()
        setShouldLoadMessages(true)
        break
      case 'REDACT_CHANNEL_MESSAGE':
      case 'UPDATE_CHANNEL_MESSAGE':
        // fetchChannel()
        handleLoadMessages()
        setShouldLoadMessages(true)
        break
    }
  }

  const setChannelInfo = async (channel: any) => {
    if(channel && channel.ChannelSummary) {
      const { ChannelSummary } = channel ? channel : null
      const { ChannelArn } = ChannelSummary ? ChannelSummary : null
      if(ChannelArn) {
        const listMessages = await utils.listChannelMessages(ChannelArn, loggedInUserArn)
        const messages = listMessages && listMessages.Messages ? listMessages.Messages : null
        const channelData: any = {
          [channelName]: {
            channelArn: ChannelArn,
            totalUnreadCount: 0,
            messageList: messages,
            userInfo: sender
          }
        }
        setChannelReducer(channelData)
        dispatch(setChannelData(channelData))
      }
      else {
        const channelData: any = {
          [channelName]: {
            channelArn: null,
            totalUnreadCount: 0,
            messageList: [],
            userInfo: sender
          }
        }
        setChannelReducer(channelData)
        dispatch(setChannelData(channelData))
      }
    }
  }

  const handleLoadMessages = async () => {
    if (channelData && channelData?.channelArn) {
      try {
        const listMessages = await utils.listChannelMessages(channelData.channelArn, loggedInUserArn)
        const messages = listMessages?.Messages || null
        if (messages) {
          const updatedChannelsReducer = { ...channelsReducer }
          const updatedMessageList = [...messages]
          updatedChannelsReducer[channelName] = {
            ...updatedChannelsReducer[channelName],
            messageList: updatedMessageList
          }
          dispatch(setChannelData(updatedChannelsReducer))
        }
      } catch (error) {
        console.error('Error loading messages:', error)
      }
    }
  };

  const handleEndConversation = async() => {
    if(channelArn) {
      const actionMarker = isEndedConversation ? MessagingConstants.REENABLE_CONVERSATION_MARKER : MessagingConstants.END_CONVERSATION_MARKER
      if (loggedInUserArn) {
        const res = await utils.updateChannelMarkerBackend(
          channelArn,
          loggedInUserArn,
          adminID,
          actionMarker,
          null)
        setIsEndedConversation(!isEndedConversation)
        setEndOrReenableActionDoer(loggedInUserArn)
        handleLoadMessages()
      }
    }
  }

  return (loggedInUserArn) ? (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
      <ThemeProvider theme={theme}>
      <div className={classes.mainContainer} >
        <Grid container direction='column' >
          { showChat ? (
            <>
            <Grid item>
            <Navbar
              receiver={receiver}
              userType={sender.role.toUpperCase()}
              username={getDisplayName(receiver, termsOfServiceAgreed)}
              onEndConversation={handleEndConversation}
              isEndedConversation={isEndedConversation}
              token={token}
              connections={updatedConnections}
              platform='mobile'
              onTimezoneClick={handleOpenTimezoneDialog}
              userArn = {sender.userArn}
              endOrReenableActionDoer={endOrReenableActionDoer}
              onMinimizeChat={onMinimizeChat}
              job={job}
              channelMetadata={channel?.metadata}
            />
          </Grid>
          <Grid item>
            <div className={classes.chatBody}>
              <Grid
                item
                container
                alignContent='center'
                className={classes.mobileChatDescriptionContainer}>
                <Typography className={classes.mobileChatDescription}>
                  {/* Subject: Your Application @ {job.company} for {job.position} */}
                  <span className={classes.multiLine}>
                    Subject: Your Application @ {job.company} for {job.position}
                  </span>
                </Typography>
              </Grid>

              {/* { sender.role == "Jobseeker" &&
                !sender.termsAgreed &&
                <Grid
                  item
                  container
                  ref={componentRef}
                  // className={classes.innerChatBody}
                  >
                    <TermsOfServiceNotification
                      company={job.company}
                      sender={sender}
                    />
                </Grid>
              } */}

              <ChatList
                channel={updatedChannel}
                channelArn={channelArn || null}
                channelName={channelName}
                senderUserArn={loggedInUserArn}
                isEndedConversation={isEndedConversation}
                userType={sender.role}
                onLoadMessages={handleLoadMessages}
                connections={updatedConnections}
                platform='mobile'
                inputFocused={inputFocused}
                channelMetadata={channel.metadata ? JSON.parse(channel.metadata) : null}
                timezone={selectedTimezone}
                endOrReenableActionDoer={endOrReenableActionDoer}
                receiver={receiver}
                job={job}
                sender={sender}
              />

            </div>
            <ChatInput
              onFocus={() => setInputFocused(prev => !prev)}
              connections={connections}
              channel={{
                channelArn: channelArn || null,
                channelName: channelName,
                metadata: channel.metadata,
                isEndedConversation: isEndedConversation
              }}
              updatedChannel={updatedChannel}
              job={job}
              receiver={{
                info: {
                  first_name: receiver.first_name,
                  last_name: receiver.last_name,
                  email: receiver.email,
                  mobile: receiver.mobile
                },
                externalUserId: receiver.external_user_id,
                role: receiver.role,
                userArn: receiver.userArn
              }}
              sender={{
                info: {
                  first_name: sender.first_name,
                  last_name: sender.last_name,
                  email: sender.email,
                  mobile: sender.mobile || null
                },
                externalUserId: sender.external_user_id,
                role: sender.role,
                userArn: sender.userArn
              }}
              onLoadMessages={handleLoadMessages}
              platform='mobile'
              adminID={adminID}
              endOrReenableActionDoer={endOrReenableActionDoer}
            />
          </Grid>
          </>
          ) : (
            <>
            {/* {sender.role !== "Jobseeker" && */}
              {/* <TermsOfService
                openPopIn={true}
                handleResponse={handleTermsAction}
                token={token}
              /> */}
              {/* } */}
            </>
          )}
        </Grid>

        <Timezone
          openPopIn={isTimezoneDialogOpen} 
          onChange={handleTimezoneChange}
          onClose={handleCloseTimezoneDialog}
          messagingUserId={sender.userArn}
          initialValue={sender?.timezone}
        />

      </div>
      </ThemeProvider>
    </>
  ) : null
  // )
}

export default Chat